import React, { useEffect, useState } from "react";
import WidgetTable from "./WidgetTable";
import WidgetScorecard from "./WidgetScorecard";
import NewsWidget from "components/NewsWidget";
import { callGaEvent } from "constants/cricket";

const WidgetHome = ({
  newsData,
  table,
  size,
  pointsData,
  scoreData,
  getStatus,
}) => {
  const IMAGE_BASE_URL = `https://akm-img-a-in.tosshub.com/sites/live-score/resources-live/images/flag_img_c/v2/`;
  // useEffect(async () => {
  //   let dataFetched;
  //   const datas = await fetch(
  //     `${baseUrl}/topicItem/getContentsBySlugAndType?limit=10&skip=1&slug=champions-trophy-2025&type=text,video`
  //   );
  //   // const datas = await fetch(
  //   //   `${baseUrl}/web/postListByCategory/election?limit=5&skip=1&type=text,video,liveblog`
  //   // );

  //   dataFetched = await datas?.json();
  //   dataFetched = dataFetched?.result?.entities;
  //   setElectionTopicData(dataFetched);
  // }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const colors = ["green", "blue", "red"];
  const [isPaused, setIsPaused] = useState(false);

  let touchStartX = 0;
  let touchEndX = 0;

  // Pause auto-slide on user interaction
  const pauseAutoSlide = () => {
    setIsPaused(true);
    // setTimeout(() => setIsPaused(false), 5000); // Resume auto-slide after 5 seconds
  };

  // Handle Touch Swipe (Mobile)
  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
    pauseAutoSlide();
  };

  const handleTouchEnd = (e) => {
    touchEndX = e.changedTouches[0].clientX;
    handleSwipe();
  };

  const handleSwipe = () => {
    const swipeThreshold = 50;
    if (touchStartX - touchEndX > swipeThreshold) {
      nextSlide();
    } else if (touchEndX - touchStartX > swipeThreshold) {
      prevSlide();
    }
    const obj = {
      eventName: "Hero_Widget_Scroll",
      pageTitle: "Home Page",
    };
    callGaEvent(obj);
  };

  // Go to next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
  };

  // Go to previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 3 - 1 : prevIndex - 1));
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);

  // Auto-Swipe Functionality (Pauses on Interaction)
  useEffect(() => {
    if (isPaused) {
      clearInterval(interval);
      return;
    } // Pause auto-slide when user interacts

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 3000);

    return () => clearInterval(interval);
  }, [isPaused]);
  return (
    <>
      {size.width <= 768 ? (
        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "768px",
            margin: "0 auto",
            backgroundColor: "#016369",
            padding: "10px",
            backgroundImage: "url('/assets/images/bg-banner.png')",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            style={{ overflow: "hidden", borderRadius: "16px" }}
          >
            <div
              style={{
                display: "flex",
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: "transform 0.5s ease-in-out",
                // backgroundColor: "#016369",
              }}
            >

              {/* tournament name section  */}
              <div
                style={{
                  width: "100%",
                  // height : "256px",
                  // backgroundColor : image,
                  flexShrink: "0",
                  padding: "32px",
                  color: "white",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    flex: 1,
                    margin: "0 10px",
                    fontSize: "16px",
                  }}
                >
                  <h2
                    style={{
                      margin: 0,
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={"/assets/images/icc-icon.svg"}
                      style={{ paddingTop: "0px" }}
                    />
                    <span style={{ paddingTop: "2px" }}>
                      ICC चैंपियंस ट्रॉफी 2025
                    </span>
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <button
                      onClick={() => {
                        const obj = {
                          eventName: "Hero_Widget_CTA_Click",
                          pageTitle: "Home Page",
                        };
                        callGaEvent(obj);

                        window.location.href = "/topic/champions-trophy";
                      }}
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        border: "1px solid #FFFFFF4D",
                        borderRadius: "10px",
                        padding: "5px 8px",
                        cursor: "pointer",
                      }}
                    >
                      News <span class="custom-external-link">↗</span>
                    </button>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        border: "1px solid #FFFFFF4D",
                        borderRadius: "10px",
                        padding: "5px 8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const obj = {
                          eventName: "Hero_Widget_CTA_Click",
                          pageTitle: "Home Page",
                        };
                        callGaEvent(obj);
                        window.location.href =
                          "/sports/cricket/champions-trophy/points-table";
                      }}
                    >
                      Points Table <span class="custom-external-link">↗</span>
                    </button>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        border: "1px solid #FFFFFF4D",
                        borderRadius: "10px",
                        padding: "5px 8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const obj = {
                          eventName: "Hero_Widget_CTA_Click",
                          pageTitle: "Home Page",
                        };
                        callGaEvent(obj);
                        window.location.href =
                          "/sports/cricket/champions-trophy/schedule";
                      }}
                    >
                      Matches <span class="custom-external-link">↗</span>
                    </button>
                  </div>
                </div>
              </div>

              {/* scorecard section  */}
              <div
                onClick={() => {
                  const obj = {
                    eventName: "Hero_Match_Card_Click",
                    pageTitle: "Home Page",
                  };
                  callGaEvent(obj);
                }}
                style={{
                  width: "100%",
                  // height : "256px",
                  // backgroundColor : image,
                  flexShrink: "0",
                  color: "white",
                  padding: "10px",
                  alignContent: "center",

                  // background: "#FFFFFF1A",
                }}
              >
                <WidgetScorecard
                  item={scoreData}
                  getStatus={getStatus}
                  scoreData={scoreData}
                />
              </div>

              {/* new section  */}
              <div
                onClick={() => {
                  const obj = {
                    eventName: "Hero_News_Card_Click",
                    pageTitle: "Home Page",
                  };
                  callGaEvent(obj);
                }}
                style={{
                  width: "100%",
                  // height : "256px",
                  // backgroundColor : image,
                  flexShrink: "0",
                  alignContent: "center",
                }}
              >
                {newsData && <NewsWidget newsData={newsData} />}
              </div>

            
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "8px" }}
          >
            {colors.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                style={{
                  width: currentIndex === index ? "10px" : "8px",
                  height: currentIndex === index ? "10px" : "8px",
                  borderRadius: "50%",
                  border:
                    currentIndex === index ? "none" : "1.5px solid #d1d5db",
                  transition: "background-color 1s ease",
                  backgroundColor:
                    currentIndex === index ? "#d9368b" : "transparent",
                }}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#016369",
            color: "white",
            padding: "20px",
            borderRadius: "10px",
            margin: "20px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundImage: "url('/assets/images/bg-banner.png')",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* Left Section */}
          {/* <div
        style={{
          flex: 1,
          backgroundColor: "#327d7f",
          padding: "15px",
          borderRadius: "10px",
          marginRight: "10px",
        }}
      > */}
          <div
            onClick={() => {
              const obj = {
                eventName: "Hero_Match_Card_Click",
                pageTitle: "Home Page",
              };
              callGaEvent(obj);
            }}
            style={{
              flex: 1,
            }}
          >
            <WidgetScorecard
              item={scoreData}
              getStatus={getStatus}
              scoreData={scoreData}
            />
          </div>
          {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <span style={{ fontSize: "14px" }}>
            India Tour Of Australia &bull; 3rd Test
          </span>
          <span style={{ color: "red", fontWeight: "bold" }}>
            LIVE <span style={{ fontSize: "18px" }}>&#9679;</span>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="india-logo.png"
              alt="IND"
              style={{ width: "30px", marginRight: "10px" }}
            />
            <span>IND</span>
          </div>
          <span>143-10 | 18-2 (6.0)*</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="westindies-logo.png"
              alt="WI"
              style={{ width: "30px", marginRight: "10px" }}
            />
            <span>WI</span>
          </div>
          <span>347-10 | 453-10</span>
        </div>
        <div style={{ marginTop: "10px", fontSize: "14px" }}>
          South Africa Women Trail By 292 Runs
        </div> */}
          {/* </div> */}

          {/* Center Section */}
          <div
            style={{
              textAlign: "center",
              flex: 1,
              margin: "0 10px",
              fontSize: "16px",
            }}
          >
            <h2
              style={{
                margin: 0,
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img
                src={"/assets/images/icc-icon.svg"}
                style={{ paddingTop: "0px" }}
              />
              <span style={{ paddingTop: "2px" }}>
                ICC चैंपियंस ट्रॉफी 2025
              </span>
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid #FFFFFF4D",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const obj = {
                    eventName: "Hero_Widget_CTA_Click",
                    pageTitle: "Home Page",
                  };
                  callGaEvent(obj);
                  window.location.href = "/topic/champions-trophy";
                }}
              >
                News <span class="custom-external-link">↗</span>
              </button>
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid #FFFFFF4D",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const obj = {
                    eventName: "Hero_Widget_CTA_Click",
                    pageTitle: "Home Page",
                  };
                  callGaEvent(obj);
                  window.location.href =
                    "/sports/cricket/champions-trophy/points-table";
                }}
              >
                Points Table <span class="custom-external-link">↗</span>
              </button>
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid #FFFFFF4D",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const obj = {
                    eventName: "Hero_Widget_CTA_Click",
                    pageTitle: "Home Page",
                  };
                  callGaEvent(obj);
                  window.location.href =
                    "/sports/cricket/champions-trophy/schedule";
                }}
              >
                Matches <span class="custom-external-link">↗</span>
              </button>
            </div>
          </div>

          {/* Right Section */}
          <div
            style={{
              flex: 1,
            }}
          // style={{
          //   flex: 1,
          //   backgroundColor: "#327d7f",
          //   padding: "15px",
          //   borderRadius: "10px",
          //   marginLeft: "10px",
          // }}
          >
            {/* <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Teams (Group A)
        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Team Name 1</span>
          <span>2 2 0 0 2</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Team Name 2</span>
          <span>2 1 0 1 2</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Team Name 3</span>
          <span>2 0 1 1 0</span>
        </div> */}
            <WidgetTable
              pointsData={pointsData}
              desktop={false}
              table={table}
              size={size}
              IMAGE_BASE_URL={IMAGE_BASE_URL}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WidgetHome;
